<script>
    import { Doughnut } from "vue-chartjs"; 

    export default {
        extends: Doughnut,
        props: {
            label: {
                type: String
            },
            chartData: {
                type: Array
            },
            options: {
                //type: Object
                
            },
            chartColors: {
                type: Object
            }
        },
        mounted() {
            const dates = this.chartData.map(d => d.abre).reverse();
            const totals = this.chartData.map(d => d.total).reverse();

            const {
                borderColor,
                pointBorderColor,
                pointBackgroundColor,
                backgroundColor
            } = this.chartColors;

            this.renderChart(
            {
                labels: dates,
                datasets: [
                    {
                        label: this.label,
                        data: totals,
                        borderColor: borderColor,
                        pointBorderColor: pointBorderColor,
                        pointBackgroundColor: pointBackgroundColor,
                        backgroundColor: backgroundColor,
                        borderWidth: 0,
                    }
                ]
            },
            this.options
            );
        }
    };
</script>