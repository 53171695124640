<template>
    <v-container fluid>
        <template>
            <div style="background:white;width:100%;margin:0%;padding:0%;">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6" style="padding-left: 2%;padding-right: 2%;">
                                <div class="row mt-4" >
                                    <div class="col">
                                        <h2 class="text-center">VENTAS POR SERIVICIO DIARIO</h2>
                                        <v-row >
                                            <v-col cols="4" style="text-align:left;" v-if="totalServicios.total_hoy">
                                                <span style="font-size:12px">Hoy</span>
                                                <h4>S/ {{totalServicios.total_hoy}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;" v-else>
                                                <span style="font-size:12px">Hoy</span>
                                                <h4>S/ 0.00</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">Ayer</span>
                                                <h4>S/ {{totalServicios.total_ayer}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">el último Mes</span>
                                                <h4>S/ {{totalServicios.total_mes}}</h4>
                                            </v-col>
                                        </v-row>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(item, indicetab) in itemDias"
                                                :key="indicetab"
                                            >
                                                <bar-chart v-if="item.tab == 'Hoy'"
                                                    :chartData="arrServiciosHoy"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                    style="backgroun: transparent"
                                                />
                                                
                                                <bar-chart v-else
                                                    :chartData="arrServiciosAyer"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs
                                            v-model="tab"
                                            style="background-color: transparent!important;"
                                        >
                                            <v-tab
                                                v-for="(item,indicetab) in itemDias"
                                                :key="indicetab"
                                                style="width:100%;background: rgb(182 182 182);color: black;margin:1%;border: 1px solid #717171;border-radius: 3px;"
                                                elevation="2"
                                            >
                                                {{ item.tab }}
                                            </v-tab>
                                        </v-tabs>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" style="padding-left: 2%;padding-right: 2%;">
                                <div class="row mt-4">
                                    <div class="col">
                                        <h2 class="text-center">VENTAS UNITARIAS POR SERVICIO</h2>
                                        <v-row >
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">Este Mes</span>
                                                <h4>{{totalServiciosMes.total_mes}} uni</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">El mes Pasado</span>
                                                <h4>{{totalServiciosMes.total_mes_pasado}} uni</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">El último año</span>
                                                <h4>{{totalServiciosMes.total_anio}} uni</h4>
                                            </v-col>
                                        </v-row>
                                        <v-tabs-items v-model="tabMes">
                                            <v-tab-item
                                                v-for="(item, indice) in itemMes"
                                                :key="indice"
                                            >
                                                <bar-chart v-if="item.tabMes == 'Este mes'"
                                                    :chartData="arrCantidadServiciosMes"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                                
                                                <bar-chart v-else
                                                    :chartData="arrCantidadServiciosMesPasado"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs
                                            v-model="tabMes"
                                            style="background-color: transparent!important;"
                                        >
                                            <v-tab
                                                v-for="(item,imes) in itemMes"
                                                :key="imes"
                                                style="width:100%;background: rgb(182 182 182);color: black;margin:1%;border: 1px solid #717171;border-radius: 3px;"
                                                elevation="2"
                                            >
                                                {{ item.tabMes }}
                                            </v-tab>
                                        </v-tabs>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-tabs-items v-model="tabIndica">
                                        <v-tab-item
                                            v-for="item in itemEncabezados"
                                            :key="item.titulo"
                                        >
                                            <v-row v-if="visualiza == 1">
                                                <v-col cols="6" class="row mt-4" >
                                                    <div class="col">
                                                        <v-card flat>
                                                            <h2 class="text-center" style="margin-bottom:5%">SERVICIOS MAS VENDIDOS EN EL MES DE {{item.titulo}}</h2>
                                                            <doughnut-Char
                                                                :chartData="arrReporteServiciosReporte"
                                                                :options="chartOptionsProductos"
                                                                :chartColors="productosColor"
                                                                :height="300"
                                                                hoverOffset="50"
                                                            />
                                                        </v-card>
                                                    </div>
                                                </v-col>
                                                <v-col class="row mt-4" cols="6">
                                                    <div class="col">
                                                        <v-card flat>
                                                            <h2 class="text-center" style="margin-bottom:5%">SERVICIOS MAS VENDIDOS EN EL MES DE {{item.titulo}}</h2>
                                                            <line-Chart
                                                                :chartData="arrReporteServiciosReporte"
                                                                :options="chartOptionsLineChart"
                                                                :chartColors="productosColor"
                                                                :height="300"
                                                                hoverOffset="50"
                                                            />
                                                        </v-card>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1">

                                </v-col>
                                <v-col cols="10">
                                    <v-tabs 
                                        v-model="tabIndica"
                                        style="background-color: transparent!important;max-width:100%!important;"
                                        
                                    >   
                                        <v-tab
                                            v-for="item in itemEncabezados"
                                            :key="item.value"
                                            style="width:100%;background: rgb(182 182 182);color: black;margin-left:1px;margin-right:1px;margin-top:1%;margin-bootom:1%;border: 1px solid #717171;border-radius: 3px;"
                                            elevation="2"
                                            @click="consultaMes(item.value)"
                                        >
                                            {{ item.titulo }}
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-container>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import LineChart from "../../../components/graficos/LineChart";
    import BarChart from "../../../components/graficos/LineBar";
    import DoughnutChar from "../../../components/graficos/DoughnutChar";
    
    export default {
        name: 'DashboardServicios',
        components: {
            LineChart,
            BarChart,
            DoughnutChar
        },
        data () {
            return {
                visualiza: 0,
                tab: null,
                tabMes: null,
                tabIndica: null,
                itemDias: [
                    { tab: 'Ayer', content: 'Tab 1 Content' },
                    { tab: 'Hoy', content: 'Tab 2 Content' },
                ],
                itemMes: [
                    { tabMes: 'El mes pasado', content: 'Tab 1 Content' },
                    { tabMes: 'Este mes', content: 'Tab 2 Content' },
                ],
                itemEncabezados: [
                    { titulo: 'ENERO', value:'01' },
                    { titulo: 'FEBRERO', value:'02'},
                    { titulo: 'MARZO', value:'03'},
                    { titulo: 'ABRIL' , value:'04'},
                    { titulo: 'MAYO', value:'05'},
                    { titulo: 'JUNIO', value:'06'},
                    { titulo: 'JULIO', value:'07'},
                    { titulo: 'AGOSTO', value:'08'},
                    { titulo: 'SETIEMBRE', value:'09'},
                    { titulo: 'OCTUBRE', value:'10' },
                    { titulo: 'NOVIEMBRE', value:'11' },
                    { titulo: 'DICIEMBRE', value:'12' },
                ],
                arrServiciosHoy: [],
                arrServiciosAyer: [],
                arrCantidadServiciosMes: [],
                arrCantidadServiciosMesPasado: [],
                arrReporteServiciosReporte: [],
                productosColor: {
                    borderColor: "#251F47",
                    pointBorderColor: "#260F26",
                    pointBackgroundColor: "#858EAB",
                    backgroundColor: [
                        "#35599D","#A3446F","#3098B6","#33939B","#4C3C9E","#7F429F"
                    ],
                },
                chartOptionsProductos:{
                    
                },
                chartOptionsLineChart:{
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    }
                },
                auxData : null,
            }
        },
        computed : {
            ...mapState('dashboard', [
                'serviciosDiarios',
                'serviciosDiariosAyer',
                'totalServicios',
                'reporteServicios',
                'serviciosMesActual',
                'serviciosMesPasado',
                'totalServiciosMes',            ])
        },
        methods: {
            ...mapActions('dashboard', [
                'getProductosVentasHoy',
                'getProductosVentasAyer',
                'getProductosGanancias',
                'getCantidadProductosMes',
                'getCantidadProductosMesPasado',
                'getTotalCantidad',
                'getReporteServicios'
            ]),
            async empieza(){

                const dataHoy  = this.serviciosDiarios;
                const dataAyer = this.serviciosDiariosAyer;
                const dataMes = this.serviciosMesActual;
                const dataMesPasado = this.serviciosMesPasado;
                
                dataHoy.forEach(d => {
                    const date = d.producto;
                    const {
                        nombre,
                        abreviado,
                        subtotal,
                    } = d;

                    this.arrServiciosHoy.push({nombre:nombre,  total: subtotal, abre:abreviado});
                });
                dataAyer.forEach(d => {
                    const date = d.producto;
                    const {
                        nombre,
                        abreviado,
                        subtotal,
                    } = d;

                    this.arrServiciosAyer.push({nombre:nombre,  total: subtotal, abre:abreviado});
                });
                dataMes.forEach(d => {
                    const date = d.producto;
                    const {
                        nombre,
                        abreviado,
                        cantidad,
                    } = d;

                    this.arrCantidadServiciosMes.push({nombre:abreviado,  total: cantidad, abre:abreviado});
                });
                dataMesPasado.forEach(d => {
                    const date = d.producto;
                    const {
                        nombre,
                        abreviado,
                        cantidad,
                        precio,
                        subtotal,
                    } = d;

                    this.arrCantidadServiciosMesPasado.push({nombre:abreviado,  total: cantidad, abre:abreviado, monto:subtotal});
                });
            },
            async consultaMes(mes){
                this.visualiza = 0;
                this.arrReporteServiciosReporte = [];
                await this.getReporteServicios(mes);
                const dataReporte = this.reporteServicios;
                console.log(dataReporte)
                dataReporte.forEach(d => {
                    const date = d.producto;
                    const {
                        producto,
                        abreviado,
                        cantidad,
                        subtotal
                    } = d;
                    this.arrReporteServiciosReporte.push({nombre:producto, total: cantidad, abre:abreviado, monto:subtotal});
                });
                console.log(this.arrReporteServiciosReporte)
                this.visualiza = 1;
                console.log(this.visualiza);
            },
            obtenerMesActual(){
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1; //January is 0!
                var yyyy = today.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }

                if (mm < 10) {
                    mm = '0' + mm;
                }

                today = mm + '/' + dd + '/' + yyyy;
                console.log(mm);
                this.tabIndica = mm;
                console.log(this.tabIndica);
                this.consultaMes(this.tabIndica);
            }
        },
        async created() {
            //this.obtenerMesActual();
            await this.empieza();
            console.log(this.productosVentasAyer)
        }
        
    }
</script>

<style scoped>

</style>